export const pincodes = [
  "177209",
  "177210",
  "177211",
  "177212",
  "177213",
  "177214",
  "177215",
  "177216",
  "177217",
  "177218",
  "177219",
  "177220",
  "177221",
  "177222",
  "177223",
  "177224",
  "177225",
  "177226",
  "177227",
  "177228",
  "177229",
  "177230",
  "177231",
  "177232",
  "177233",
  "177234",
  "177235",
  "177236",
  "177237",
  "177238",
  "177239",
  "177240",
  "177241",
  "177242",
  "177243",
  "177244",
  "177245",
  "177246",
  "177247",
  "177248",
  "177249",
  "177250",
  "177251",
  "177252",
  "177253",
  "177254",
  "177255",
  "177256",
  "177257",
  "177258",
  "177259",
  "177260",
  "177261",
  "177262",
  "177263",
  "177264",
  "177265",
  "177266",
  "177267",
  "177268",
  "177269",
  "177270",
  "177271",
  "177272",
  "177273",
  "177274",
  "177275",
  "177276",
  "177277",
  "177278",
  "177279",
  "177280",
  "177281",
  "177282",
  "177283",
  "177284",
  "177285",
  "177286",
  "177287",
  "177288",
  "177289",
  "177290",
  "177291",
  "177292",
  "177293",
  "177294",
  "177295",
  "177296",
  "177297",
  "177298",
  "177299",
  "177300",
  "177301",
  "177302",
  "177303",
  "177304",
  "177305",
  "177306",
  "177307",
  "177308",
  "177309",
  "177310",
  "177311",
  "177312",
  "177313",
  "177314",
  "177315",
  "177316",
  "177317",
  "177318",
  "177319",
  "177320",
  "177321",
  "177322",
  "177323",
  "177324",
  "177325",
  "177326",
  "177327",
  "177328",
  "177329",
  "177330",
  "177331",
  "177332",
  "177333",
  "177334",
  "177335",
  "177336",
  "177337",
  "177338",
  "177339",
  "177340",
  "177341",
  "177342",
  "177343",
  "177344",
  "177345",
  "177346",
  "177347",
  "177348",
  "177349",
  "177350",
  "177351",
  "177352",
  "177353",
  "177354",
  "177355",
  "177356",
  "177357",
  "177358",
  "177359",
  "177360",
  "177361",
  "177362",
  "177363",
  "177364",
  "177365",
  "177366",
  "177367",
  "177368",
  "177369",
  "177370",
  "177371",
  "177372",
  "177373",
  "177374",
  "177375",
  "424206",
  "425114",
  "425111",
  "424205",
  "425103",
  "425303",
  "425327",
  "425111",
  "424111",
  "425105",
  "425115",
  "425111",
  "425206",
  "525001",
  "422012",
  "425307",
  "425001",
  "421605",
];
