import { useEffect } from "react";
import Footer from "../components/Home/Footer";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Rehaabit Privacy Policy - How We Protect Your Data </title>
        <meta
          name="description"
          content="Your privacy matters to us. Read Rehaabit's Privacy Policy to understand how we collect, protect, and use your personal data while delivering home service solutions."
        />
        <meta
          name="keywords"
          content="Rehaabit privacy policy, data protection, user privacy, personal data, how we use your data, privacy practices, Rehaabit data security"
        />
      </Helmet>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="p-6 max-w-5xl bg-white shadow-md rounded-md my-10">
          <h1
            className="text-3xl font-bold mb-4 text-center"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            PRIVACY POLICY
          </h1>

          <section className="mb-6">
            <p style={{ fontFamily: "Roboto, sans-serif" }}>
              Welcome to Rehaabit's privacy policy ("Privacy Policy" or
              "Policy").
            </p>
            <p className="mt-2" style={{ fontFamily: "Roboto, sans-serif" }}>
              Rehaabit and its affiliates (collectively, "Rehaabit", "we" or
              "us") are engaged in the business of providing web-based solutions
              to facilitate connections between customers that seek specific
              services and service professionals that offer these services. This
              Policy outlines our practices in relation to the collection,
              storage, usage, processing, and disclosure of personal data that
              you have consented to share with us when you access, use, or
              otherwise interact with our website available at{" "}
              <a
                href="https://www.rehaabit.com/"
                className="text-blue-500 underline"
              >
                https://www.rehaabit.com/
              </a>{" "}
              or mobile application 'Rehaabit' (collectively, "Platform") or
              avail products or services that Rehaabit Company offers you on or
              through the Platform (collectively, the "Services").
            </p>
            <p className="mt-2" style={{ fontFamily: "Roboto, sans-serif" }}>
              At Rehaabit, we are committed to protecting your personal data and
              respecting your privacy. In order to provide you with access to
              the Services or the Professional Services, we have to collect and
              otherwise process certain data about you. This Policy explains how
              we process and use personal data about you.
            </p>
            <p className="mt-2" style={{ fontFamily: "Roboto, sans-serif" }}>
              Please note that unless specifically defined in this Policy,
              capitalized terms shall have the same meaning ascribed to them in
              our Terms and Conditions, available at
              <a
                href="https://www.rehaabit.com/terms"
                className="text-blue-500 underline"
              >
                {" "}
                https://www.rehaabit.com/terms-and-conditions
              </a>{" "}
              ("Terms"). Please read this Policy in consonance with the Terms.
            </p>
            <p className="mt-2" style={{ fontFamily: "Roboto, sans-serif" }}>
              By using the Services, you confirm that you have read and agree to
              be bound by this Policy and consent to the processing activities
              described under this Policy.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              1. BACKGROUND AND KEY INFORMATION
            </h2>

            <h3 className="text-xl font-medium mb-1">
              (a) How this Policy applies:
            </h3>
            <p>
              This Policy applies to individuals who access or use the Services
              or otherwise avail the Professional Services. For the avoidance of
              doubt, references to "you" across this Policy are to an end user
              that uses the Platform.
            </p>
            <p className="mt-2">
              By using the Platform, you consent to the collection, storage,
              usage, and disclosure of your personal data, as described in and
              collected by us in accordance with this Policy.
            </p>

            <h3 className="text-xl font-medium mt-4 mb-1">
              (b) Review and Updates:
            </h3>
            <p>
              We regularly review and update our Privacy Policy, and we request
              you to regularly review this Policy. It is important that the
              personal data we hold about you is accurate and current. Please
              let us know if your personal data changes during your relationship
              with us.
            </p>

            <h3 className="text-xl font-medium mt-4 mb-1">
              (c) Third-Party Services:
            </h3>
            <p>
              The Platform may include links to third-party websites, plug-ins,
              services, and applications ("Third-Party Services"). Clicking on
              those links or enabling those connections may allow third parties
              to collect or share data about you. We neither control nor endorse
              these Third-Party Services and are not responsible for their
              privacy statements. When you leave the Platform or access
              third-party links through the Platform, we encourage you to read
              the privacy policy of such third-party service providers.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              2. PERSONAL DATA THAT WE COLLECT
            </h2>
            <p>
              (a) We collect different types of personal data about you. This
              includes, but is not limited to:
            </p>
            <ul className="list-disc pl-6 mt-2">
              <li>
                Contact Data, such as your mailing or home address, location,
                email addresses, and mobile numbers.
              </li>
              <li>
                Identity and Profile Data, such as your name, username or
                similar identifiers, photographs, and gender.
              </li>
              <li>
                Marketing and Communications Data, such as your address, email
                address, information posted in service requests, offers, wants,
                feedback, comments, pictures and discussions in our blog and
                chat boxes, responses to user surveys and polls, your
                preferences in receiving marketing communications from us and
                our third parties, and your communication preferences.
              </li>
              <li>
                Technical Data, which includes your IP address, browser type,
                internet service provider, details of operating system, access
                time, page views, device ID, device type, frequency of visiting
                our website and use of the Platform, website and mobile
                application activity, clicks, date and time stamps, location
                data, and other technology on the devices that you use to access
                the Platform.
              </li>
              <li>
                Transaction Data, such as details of the Services or
                Professional Services you have availed, a limited portion of
                your credit or debit card details for tracking transactions that
                are provided to us by payment processors, and UPI IDs for
                processing payments.
              </li>
              <li>
                Usage Data, which includes information about how you use the
                Services and Professional Services, your activity on the
                Platform, booking history, user taps and clicks, user interests,
                time spent on the Platform, details about user journey on the
                mobile application, and page views.
              </li>
            </ul>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              3. HOW DO WE COLLECT PERSONAL DATA?
            </h2>
            <p>
              We use different methods to collect personal data from and about
              you including through:
            </p>
            <ul className="list-disc pl-6 mt-2">
              <li>Direct Interactions</li>
              <li>Automated technologies or interactions</li>
              <li>Third parties or publicly available sources</li>
            </ul>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              4. HOW DO WE USE YOUR PERSONAL DATA?
            </h2>
            <p>
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data where we need to
              provide you with the Services, enable you to use the Professional
              Services, or where we need to comply with a legal obligation.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">5. COOKIES</h2>
            <p>
              We use cookies to help us distinguish you from other users of the
              Platform, understand and save your preferences for future visits,
              keep track of advertisements and compile aggregate data about site
              traffic and site interaction so that we can offer you a seamless
              user experience.
            </p>
          </section>

          {/* Sections 6-14 would follow a similar pattern */}
          {/* Sections 1-5 remain the same as in the previous response */}

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              6. DISCLOSURES OF YOUR PERSONAL DATA
            </h2>
            <p>
              (a) We may share your personal data with third parties set out
              below for the purposes set out in Section 4:
            </p>
            <ul className="list-disc pl-6 mt-2">
              <li>
                Service professionals to enable them to provide you with
                Professional Services;
              </li>
              <li>
                Internal third parties, which are other companies within the
                Rehaabit group of companies.
              </li>
              <li>
                External third parties such as:
                <ul className="list-circle pl-6 mt-1">
                  <li>
                    trusted third parties such as our associate partners, and
                    service providers that provide services for us or on our
                    behalf;
                  </li>
                  <li>
                    analytic service providers and advertising networks that
                    conduct web analytics for us to help us improve the
                    Platform;
                  </li>
                  <li>
                    other registered users on our Platform upon your request or
                    where you explicitly consent to such disclosure; and
                  </li>
                  <li>
                    regulators and other bodies, as required by law or
                    regulation.
                  </li>
                </ul>
              </li>
            </ul>
            <p className="mt-2">
              (b) We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. We do
              not allow our third-party service providers to use your personal
              data for their own purposes and only permit them to process your
              personal data for specified purposes and in accordance with our
              instructions.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              7. YOUR RIGHTS IN RELATION TO YOUR PERSONAL DATA
            </h2>
            <p>
              (a) Access and Updating your Personal Data: You can request
              Rehaabit Company for a copy of your personal data by sending an
              email to support@rehaabit.com. Rehaabit Company may take up to 7
              (seven) working days to respond to such a request.
            </p>
            <p className="mt-2">
              (b) Opting-out of Marketing and Promotional Communications: When
              we send you marketing and promotional content through email, we
              make best efforts to provide you with the ability to opt-out of
              such communications by using the opt-out instructions provided in
              such emails.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              8. DELETION OF ACCOUNT AND PERSONAL DATA
            </h2>
            <p>
              You may delete your account as well as your personal data stored
              with Rehaabit Company by sending an email to support@rehaabit.com.
              Rehaabit Company may take up to 7 (seven) working days to process
              your request.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              9. TRANSFERS OF YOUR PERSONAL DATA
            </h2>
            <p>
              (a) We comply with applicable laws in respect of storage and
              transfers of personal data. As a part of your use of the Services,
              the information and personal data you provide to us may be
              transferred to and stored in countries other than the country you
              are based in.
            </p>
            <p className="mt-2">
              (b) By submitting your information and personal data to us, you
              agree to the transfer, storage, and processing of such information
              and personal data in the manner described above.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">10. DATA SECURITY</h2>
            <p>
              (a) We implement appropriate security measures and
              privacy-protective features on our Platform including encryption,
              password protection, call masking, and physical security measures
              to protect your personal data from unauthorized access and
              disclosure, and follow standards prescribed by applicable law.
            </p>
            <p className="mt-2">
              (b) Where you have chosen a password that enables you to access
              certain parts of the Services or Professional Services, you are
              responsible for keeping this password secret and confidential.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">11. DATA RETENTION</h2>
            <p>
              (a) You agree and acknowledge that your personal data will
              continue to be stored and retained by us for as long as necessary
              to fulfill our stated purpose(s) and for a reasonable period after
              the termination of your account on the Platform or access to the
              Services to comply with our legal rights and obligations.
            </p>
            <p className="mt-2">
              (b) In some circumstances, we may aggregate your personal data (so
              that it can no longer be associated with you) for research or
              statistical purposes, in which case we may use this information
              indefinitely without further notice to you.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              12. BUSINESS TRANSITIONS
            </h2>
            <p>
              You are aware that in the event we go through a business
              transition, such as a merger, acquisition by another organization,
              or sale of all or a portion of our assets, your personal data
              might be among the assets transferred.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              13. USER GENERATED CONTENT
            </h2>
            <p>
              We invite you to post content on our Platform, including your
              comments, feedback, pictures, or any other information that you
              would like to be made available on our Platform. Please note that
              such content will be available to all visitors to our Platform and
              may become public. We cannot prevent such information from being
              used in a manner that is contrary to this Policy, applicable laws,
              or your personal privacy, and we disclaim all liability (express
              or implied) in this regard.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              14. UPDATES TO THIS POLICY
            </h2>
            <p>
              (a) We may occasionally update this Policy. If we make changes to
              this Policy, we will upload the revised policy on the Platform or
              share it with you through other means, such as email. To the
              extent permitted under applicable law, by using our Platform after
              such notice, you consent to updates made to this Policy.
            </p>
            <p className="mt-2">
              (b) We encourage you to periodically review this Policy for the
              latest information on our privacy practices.
            </p>
          </section>

          <section
            className="mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h2 className="text-2xl font-semibold mb-2">
              15. GRIEVANCE OFFICER
            </h2>
            <p>
              If you have any questions about this Policy, how we process or
              handle your personal data, or otherwise, you may reach out to us,
              with your queries, grievances, feedback, and comments at
              <a
                href="mailto:support@rehaabit.com"
                className="text-blue-500 underline"
              >
                {" "}
                support@rehaabit.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
